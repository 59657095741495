import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "../Input";

export default function TransactionRecords() {
    const [transactions, setTransactions] = useState([]);
    const [totalBalance, setTotalBalance] = useState(0);
    const [history, setHistory] = useState({});
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        const adminSessionId = localStorage.getItem("admin_session_id");
        if (!adminSessionId) {
            navigate("/admin/login");
            return;
        }

        // Fetch transactions
        const fetchTransactions = async () => {
            try {
                const response = await axios.post("/admin/transactions", {
                    id: adminSessionId,
                });
                const res = response.data;

                if (res.status === "200") {
                    const transactionData = res.data;
                    const totalBalanceData = transactionData.pop(); // Last item is total balance
                    setTotalBalance(totalBalanceData.users_total_balance);
                    setTransactions(transactionData);
                } else {
                    console.error(res.message);
                }
            } catch (error) {
                console.error("Error fetching transactions:", error);
            }
        };

        // Fetch history
        const fetchHistory = async () => {
            try {
                const response = await axios.get("/admin/history");
                const res = response.data;

                if (res.status === "200") {
                    setHistory(res.data);
                } else {
                    alert(res.message);
                }
            } catch (error) {
                console.error("Error fetching history:", error);
            }
        };

        fetchTransactions();
        fetchHistory();
    }, [navigate]);

    const filteredTransactions = transactions.filter(
        (transaction) =>
            transaction.trx_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            transaction.trx_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="admin-dash-page custom-scroll-x">
            <Helmet>
                <title>Transactions</title>
            </Helmet>
            <header className="flex-between wrap" style={{ marginBottom: ".2rem" }}>
                <h2>Transactions/Purchases</h2>
                <div className="flex-between wrap">
                    <a href="/admin/dashboard">Payment Requests</a>
                    <a href="/admin/users">Users</a>
                    <a href="/admin/login">Log Out</a>
                </div>
                <div>
                    <Input
                        type="search"
                        value={searchTerm}
                        placeholder="Search Transaction by ID or Email"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </header>

            <div style={{ marginBottom: "1rem" }}>
                <h3>Today's Transactions Snapshot</h3>
                <p>Users Total Balance: {totalBalance}</p>
                {/* <p>Money In: {history.money_in}</p> */}
                <p>Data Sold: {history.data_sold}</p>
                <p>Airtime Sold: {history.airtime_sold}</p>
                <p>Verified NIN: {history.verified_nin}</p>
                <p>Verified BVN: {history.verified_bvn}</p>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Mode</th>
                        <th>For</th>
                        <th>Old Bal</th>
                        <th>New Bal</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTransactions.length > 0 ? (
                        filteredTransactions.map((transaction) => (
                            <tr key={transaction.trx_id}>
                                <td style={{ userSelect: "text" }}>{transaction.trx_id}</td>
                                <td style={{ userSelect: "text" }}>{transaction.trx_email}</td>
                                <td>{transaction.trx_amount}</td>
                                <td>{transaction.trx_type}</td>
                                <td>{transaction.trx_mode}</td>
                                <td>{transaction.trx_for}</td>
                                <td>{transaction.trx_old_bal}</td>
                                <td>{transaction.trx_new_bal}</td>
                                <td>{transaction.trx_status}</td>
                                <td>{new Date(transaction.trx_date).toLocaleString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>
                                No transactions found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}