import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const PublicRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    if (localStorage.getItem('session_id')) {
      navigate('/user/dashboard'); // Redirect to dashboard if logged in
    }
  }, [navigate]);

  return <>{!localStorage.getItem('session_id') && children}</>; // Render children only if not logged in
};


export const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('session_id')) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [navigate]);

  return <>{localStorage.getItem('session_id') && children}</>; // Render children only if logged in
};