import React, { useEffect } from "react";

export default function DisableWin() {
  useEffect(() => {
    const target = document.getElementById("disable-win");

    // Function to disable key events
    const disableKeys = (e) => {
      e.preventDefault();
    };

    // Callback function for IntersectionObserver
    const observerCallback = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // Add event listener to disable key events
        window.addEventListener("keydown", disableKeys, true);
        window.addEventListener("keyup", disableKeys, true);
      } else {
        // Remove event listener to enable key events
        window.removeEventListener("keydown", disableKeys, true);
        window.removeEventListener("keyup", disableKeys, true);
      }
    };

    // Create an IntersectionObserver with threshold set to 0
    const observer = new IntersectionObserver(observerCallback, {
      root: null, // observe within viewport
      threshold: 0, // trigger as soon as any part of the div is visible
    });

    // Start observing the target div
    if (target) {
      observer.observe(target);
    }

    // Cleanup function to remove observer and key events when component unmounts
    return () => {
      if (target) {
        observer.unobserve(target);
      }
      window.removeEventListener("keydown", disableKeys, true);
      window.removeEventListener("keyup", disableKeys, true);
    };
  }, []);

  return <div id="disable-win"></div>;
}
