import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { PublicRoute, PrivateRoute } from "./app/components/Routes";
import { GlobalLoader } from "./app/components/Loader";
import "./App.css";

import AdminDashboard from "./app/components/admin/Dashboard";
import TransactionRecords from "./app/components/admin/transactions";
import Users from "./app/components/admin/Users";
import LoginPage from "./app/components/admin/LoginPage";

const App = lazy(() => import("./App"));

const Login = lazy(() => import("./app/components/auth/Login"));
const SignUp = lazy(() => import("./app/components/auth/Signup"));

const Main = lazy(() => import("./app/components/user/Main"));
const Dashboard = lazy(() => import("./app/components/user/Dashboard"));
const Wallet = lazy(() => import("./app/components/user/Wallet"));
const Transactions = lazy(() => import("./app/components/user/Transactions"));
const Settings = lazy(() => import("./app/components/user/Settings"));
const ChangePassword = lazy(() =>
	import("./app/components/user/ChangePassword")
);
const { ResetPassword, ResetPasswordForm } = await import(
	"./app/components/user/ResetPassword"
);
const ResetPIN = lazy(() => import("./app/components/user/ResetPIN"));

const NotFound = lazy(() => import("./app/components/pages/NotFound"));
const HowItWorks = lazy(() => import("./app/components/pages/HowItWorks"));
const About = lazy(() => import("./app/components/pages/About"));
const Disclaimer = lazy(() => import("./app/components/pages/Disclaimer"));
const Privacy = lazy(() => import("./app/components/pages/Privacy"));
const Terms = lazy(() => import("./app/components/pages/Terms"));
const Contact = lazy(() => import("./app/components/pages/Contact"));

const Pricing = lazy(() => import("./app/components/user/Pricing"));
const ChangePIN = lazy(() => import("./app/components/user/changePIN"));

// Services
const BuyAirtime = lazy(() =>
	import("./app/components/user/services/BuyAirtime")
);
const BuyData = lazy(() => import("./app/components/user/services/BuyData"));
const Verify = lazy(() => import("./app/components/user/services/Verify"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Router>
		<Suspense fallback={<GlobalLoader />}>
			<Routes>
				{/* Other routes */}
				<Route
					path="/"
					element={
						<PublicRoute>
							<App />
						</PublicRoute>
					}>
					<Route
						path="login"
						element={
							<PublicRoute>
								<Login />
							</PublicRoute>
						}
					/>
					<Route
						path="signup"
						element={
							<PublicRoute>
								<SignUp />
							</PublicRoute>
						}
					/>
					<Route
						path="reset_password"
						element={
							<PublicRoute>
								<ResetPassword />
							</PublicRoute>
						}
					/>
					<Route
						path="reset_password/:token"
						element={
							<PublicRoute>
								<ResetPasswordForm />
							</PublicRoute>
						}
					/>
					<Route
						path="payment_callback"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
				</Route>
				{/* Pages route */}
				<Route path="/how_it_works" element={<HowItWorks />} />
				<Route path="/about_us" element={<About />} />
				<Route path="/disclaimer" element={<Disclaimer />} />
				<Route path="/terms_of_use" element={<Terms />} />
				<Route path="/privacy_policy" element={<Privacy />} />
				<Route path="/contact_us" element={<Contact />} />
				{/* Admin routes */}
				<Route path="/admin/login" element={<LoginPage />} />
				<Route
					path="/admin/dashboard"
					element={
						<PrivateRoute>
							<AdminDashboard />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin/users"
					element={
						<PrivateRoute>
							<Users />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin/transactions"
					element={
						<PrivateRoute>
							<TransactionRecords />
						</PrivateRoute>
					}
				/>
				{/* User-specific routes */}
				<Route
					path="/user"
					element={
						<PrivateRoute>
							<Main />
						</PrivateRoute>
					}>
					<Route
						index
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route
						path="dashboard"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route
						path="funding"
						element={
							<PrivateRoute>
								<Wallet />
							</PrivateRoute>
						}
					/>
					<Route
						path="transactions"
						element={
							<PrivateRoute>
								<Transactions />
							</PrivateRoute>
						}
					/>
					<Route
						path="settings"
						element={
							<PrivateRoute>
								<Settings />
							</PrivateRoute>
						}
					/>
					<Route
						path="buy_data"
						element={
							<PrivateRoute>
								<BuyData />
							</PrivateRoute>
						}
					/>
					<Route
						path="buy_airtime"
						element={
							<PrivateRoute>
								<BuyAirtime />
							</PrivateRoute>
						}
					/>
					<Route
						path="verify"
						element={
							<PrivateRoute>
								<Verify />
							</PrivateRoute>
						}
					/>
					<Route path="pricing" element={<Pricing />} />
					<Route
						path="delete_account"
						element={
							<PrivateRoute>
								<Settings />
							</PrivateRoute>
						}
					/>
				</Route>
				<Route
					path="reset_pin"
					element={
						<PrivateRoute>
							<ResetPIN />
						</PrivateRoute>
					}
				/>
				<Route
					path="/user/change_pin"
					element={
						<PrivateRoute>
							<ChangePIN />
						</PrivateRoute>
					}
				/>
				<Route
					path="/user/change_password"
					element={
						<PrivateRoute>
							<ChangePassword />
						</PrivateRoute>
					}
				/>
				{/* Catch-all route */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Suspense>
	</Router>
);
