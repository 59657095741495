import axios from "axios"
import { useEffect, useState } from "react"
import Input from '../Input'
import Button from '../Button'
import Alert from '../Alert'
import Spinner from '../Loader'
import DisableWin from '../DisableWin'
import Helmet from 'react-helmet'
import { useNavigate } from 'react-router-dom';


export default function LoginPage()
{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        } else {
            setPassword(e.target.value);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(!loading);

        axios({
            method: "post",
            url: "/admin/login",
            data: {id: email, password: password}
        })
        .then((response) => {
            const res = response.data
            if (res['status'] === "200") {
                localStorage.setItem('admin_session_id', res['admin_session_id'])
                navigate('/admin/dashboard')
            } else {
                setMsg('Invalid credentials was provided. Please check and try again.');
                setLoading(false);
            }
        })
   }
   useEffect(() => {
    localStorage.removeItem('admin_session_id');
   },[])
    return (
        <div className="admin-login-page flex-column-center">
            <Helmet><title>Admin Login</title></Helmet>
            <h1>Admin login</h1>
            <form onSubmit={handleSubmit} className="flex-column">
                {msg && <Alert type="danger" heading="Access denied" text={msg} />}
                <Input onChange={handleChange} value={email} type="text" name="email" placeholder="Login ID" autoComplete="on" autoFocus={true} required={true} />
                <Input onChange={handleChange} value={password} type="password" name="password" placeholder="Password" required={true} />
                <Button type="submit" text={loading? <Spinner />:"Login"} className="btn-md btn-filled" />
            </form>
            {loading && <DisableWin />}
        </div>
    )
}