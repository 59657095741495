export default function Select({id, required, value, name, className, style, children, onChange}) {
    return (
        <span className="">
            <select
                onChange={onChange}
                name={name}
                id={id}
                className={className}
                style={style}
                required={required}
                value={value}
            >
                {children}
            </select>
        </span>
    );
}