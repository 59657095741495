import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../Button";
import Input from "../Input";

export default function AdminDashboard() {
	const [data, setData] = useState(null);
	const [term, setTerm] = useState("");

	const navigate = useNavigate();

	const handlePaymentConfirmation = (e) => {
		if (e.target.innerText !== "Confirmed") {
			e.target.innerText = "Confirmed";
			e.target.className = "btn-sm btn-filled btn-disabled";

			axios({
				method: "post",
				url: "/admin/balance/topup",
				data: {
					id: e.target.dataset.id,
					amount: e.target.dataset.amount,
				},
			})
				.then((response) => {
					const res = response.data;
					if (res["status"] === "200") {
						alert(
							`User account has been credited with ${e.target.dataset.amount}.`
						);

						axios({
							method: "post",
							url: "/admin/confirm_payment",
							data: { entry_num: e.target.dataset.entrynum },
						}).catch((err) => console.log(err));
					} else {
						alert(res["message"]);
					}
				})
				.catch((err) => console.log(err));
		}
	};

	useEffect(() => {
		axios({
			method: "get",
			url: "/admin/payment_requests",
			params: { admin_id: localStorage.getItem("admin_session_id") },
		})
			.then((response) => {
				const res = response.data;
				if (res["status"] === "200") {
					setData(res["data"]);
				}
			})
			.catch((err) => console.log(err));

		const id = localStorage.getItem("admin_session_id");
		if (!id) navigate("/admin/login");
	}, [navigate]);
	return (
		<div className="admin-dash-page custom-scroll-x">
			<Helmet>
				<title>Admin Dashboard</title>
			</Helmet>
			<header className="flex-between wrap">
				<h2>Payment requests</h2>
				<div className="flex wrap">
					<a href="/admin/users">Users</a>
					<a href="/admin/transactions">Transactions/Purchases</a>
					<a href="/admin/login">Log out</a>
				</div>
				<div>
					<Input
						onChange={(e) => setTerm(e.target.value)}
						value={term}
						type="search"
						placeholder="Search User by Email or Name"
					/>
				</div>
			</header>
			<table className="">
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Amount</th>
						<th>Date</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data
							.filter((user) => {
								return (
									user.email.toLowerCase().includes(term.toLowerCase()) ||
									user.name.toLowerCase().includes(term.toLowerCase())
								);
							})
							.map((p) => (
								<tr key={p.entry_num}>
									<td>{p.user_id}</td>
									<td>{p.name}</td>
									<td style={{ userSelect: "text" }}>{p.email}</td>
									<td style={{ userSelect: "text" }}>{p.phone_number}</td>
									<td>N{p.amount}</td>
									<td>{new Date(p.date).toLocaleString()}</td>
									<td>
										<Button
											handleClick={handlePaymentConfirmation}
											text={p.confirmed ? "Confirmed" : "Confirm"}
											className={`btn-sm ${
												p.confirmed ? "btn-filled btn-disabled" : "btn-outlined"
											}`}
											dataId={p.user_id}
											dataAmount={p.amount}
											dataEntryNum={p.entry_num}
										/>
									</td>
								</tr>
							))}
				</tbody>
			</table>
		</div>
	);
}
