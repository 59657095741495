import MoonLoader from 'react-spinners/MoonLoader';
import PulseLoader from 'react-spinners/PulseLoader';

export default function Loader(props)
{
const override = {
    display: "flex",
    margin: "0 auto"
};
    return ( 
        <>
            {props.type==="moonLoader"?
            
                <MoonLoader
                    cssOverride={override}
                    color={props.color || "#088080"}
                    size={props.size || 70}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
           
        :
                <PulseLoader
                    cssOverride={override}
                    color={props.color || "#e8e8e8"}
                    size={props.size || 7}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                
            }
        </>
     );
}


export function GlobalLoader(props)
{
    return (
        <div className="flex-column-center" style={{height: "100vh", width: "100vw", backgroundColor: "#ffffff"}}><Loader type="moonLoader" size={props.size || 70} /></div>
    );
}