export default function Button(props) {
    return (
        <button
            id={props.id}
            type={props.type || 'button'}
            className={props.className}
            style={props.style}
            data-type={props.dataType}
            data-id={props.dataId}
            data-amount={props.dataAmount}
            data-entrynum={props.dataEntryNum}
            onClick={props.handleClick}
        >{props.text}</button>
    );
}