import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "../Input";
import Button from "../Button";
import Select from "../Select";
import Loader from "../Loader";

// Helper function to format date as "DDMMYYYY"
const formatDate = (date) => {
    const fullDate = new Date(date);
    const day = fullDate.getDate();
    const month = fullDate.getMonth() + 1; // Months are 0-based
    const year = fullDate.getFullYear();

    return `${day}${month}${year}`;
};

export default function Users() {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [todayNewUsers, setTodayNewUsers] = useState(0);
    const [term, setTerm] = useState("");
    const [email, setEmail] = useState("");
    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    // Fetch users on mount
    useEffect(() => {
        const fetchUsers = async () => {
            const adminSessionId = localStorage.getItem("admin_session_id");
            if (!adminSessionId) {
                navigate("/admin/login");
                return;
            }

            try {
                const response = await axios.post("/admin/users", { id: adminSessionId });
                const { status, data, message } = response.data;

                if (status === "200") {
                    setUsers(data);
                    setFilteredUsers(data);

                    // Count today's new users
                    const today = formatDate(new Date());
                    const newUsersCount = data.reduce((count, user) => {
                        return formatDate(user.date_created) === today ? count + 1 : count;
                    }, 0);
                    setTodayNewUsers(newUsersCount);
                } else {
                    alert(message);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchUsers();
    }, [navigate]);

    // Filter users based on the search term
    useEffect(() => {
        if (term.trim()) {
            setFilteredUsers(
                users.filter(
                    (user) =>
                        user.user_id.toLowerCase().includes(term.toLowerCase()) ||
                        user.email.toLowerCase().includes(term.toLowerCase())
                )
            );
        } else {
            setFilteredUsers(users);
        }
    }, [term, users]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post("/admin/update_balance", {
                email,
                amount,
                type,
            });
            const { status, message } = response.data;

            if (status === "success") {
                alert("Success!");
                setEmail("");
                setAmount("");
                setType("");
            } else {
                alert(message);
            }
        } catch (err) {
            console.error(err);
            alert("An error occurred while updating the balance.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="admin-dash-page custom-scroll-x">
            <Helmet>
                <title>Intellisub Users</title>
            </Helmet>

            <header className="flex-between wrap">
                <h1 style={{ fontSize: "1.5em", marginBottom: "1rem" }}>
                    {`Total Users: ${users.length} (+ ${todayNewUsers} New Today)`}
                </h1>
                <div className="flex wrap" style={{ margin: "1rem 0" }}>
                    <a href="/admin/dashboard">Payment Requests</a>
                    <a href="/admin/transactions">Transactions/Purchases</a>
                    <a href="/admin/login">Log Out</a>
                </div>
                <Input
                    type="search"
                    value={term}
                    placeholder="Search User by ID or Email"
                    onChange={(e) => setTerm(e.target.value)}
                />
            </header>

            <form onSubmit={handleSubmit} style={{ margin: "1rem" }}>
                <Select required value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="" disabled>
                        Type
                    </option>
                    <option value="credit">Credit</option>
                    <option value="debit">Debit</option>
                </Select>
                <Input
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    type="number"
                    name="amount"
                    value={amount}
                    placeholder="Amount"
                    required
                    onChange={(e) => setAmount(e.target.value)}
                />
                <Button
                    type="submit"
                    text={isLoading ? <Loader /> : "Credit/Debit User"}
                    className="btn-filled btn-md"
                />
            </form>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Balance</th>
                        <th>Token</th>
                        <th>Photo</th>
                        <th>Date Created</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((user) => (
                            <tr key={user.user_id}>
                                <td style={{ userSelect: "text" }}>{user.user_id}</td>
                                <td style={{ userSelect: "text" }}>{user.name}</td>
                                <td style={{ userSelect: "text" }}>{user.email}</td>
                                <td>{parseFloat(user.balance).toFixed(2)}</td>
                                <td>{user.token}</td>
                                <td>{user.image_data ? "true" : "false"}</td>
                                <td>{user.date_created}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" style={{ textAlign: "center" }}>
                                No users found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
