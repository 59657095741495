import { useRef } from "react";
import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function Input(props) {
	const [hidePassword, setHidePassword] = useState(true);
	const inp = useRef();

	const [isFocused, setIsFocused] = useState(false);

	const togglePassword = () => {
		setHidePassword(!hidePassword);

		if (hidePassword) {
			inp.current.setAttribute("type", "text");
		} else {
			inp.current.setAttribute("type", "password");
		}
	};

	return (
		<span
			className={
				props.type === "password" ? "password-input-wrapper" : "input-wrapper"
			}>
			<label
				className={`placeholder ${
					isFocused ? "focused placeholder-yellow-text" : ""
				}
                    ${props.value ? "focused" : ""}
                    ${props.error ? "placeholder-red-text" : ""}
                    ${
											props.disabled || props.readOnly
												? "placeholder-default-text"
												: ""
										}
                `}>
				{props.placeholder}
			</label>
			<input
				ref={inp}
				type={props.type}
				className={`input ${props.error && "input-border-red"} ${
					props.className
				}`}
				id={props.id}
				name={props.name}
				required={props.required}
				readOnly={props.readOnly}
				disabled={props.disabled}
				autoFocus={props.autoFocus}
				autoComplete={props.autoComplete}
				value={props.value}
				onChange={props.onChange}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
			/>
			{props.type === "password" && (
				<div
					onClick={togglePassword}
					className="password-toggler-btn-wrapper flex-center">
					{hidePassword ? (
						<Eye id="password-toggle-btn" />
					) : (
						<EyeSlash id="password-toggle-btn" />
					)}
				</div>
			)}
			{/* {props.errorMessage && <span className="input-err-msg">{props.errorMessage}</span>} */}
		</span>
	);
}
